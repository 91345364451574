$(function () {
    // Indicate the current page in the navigation bar (if a link exists there).
    $(".navigation li a").each(function() {
        if(this.href == window.location) $(this).parent().addClass("active");
    });
    $(".breadcrumb li a").each(function() {
        if(this.href == window.location)$(this).parent().addClass("active");
    });
    // Create a clone of the menu, right next to original.
    $('.navigation').addClass('original').clone().insertAfter('.navigation').addClass('cloned').css('position','fixed').css('top','0').css('margin-top','0').css('z-index','1000').removeClass('original').hide();

    scrollIntervalID = setInterval(stickIt, 10);


    function stickIt() {

        var orgElementPos = $('.original').offset();
        orgElementTop = orgElementPos.top;

        if ($(window).scrollTop() >= (orgElementTop)) {
            // scrolled past the original position; now only show the cloned, sticky element.
            $('.navigation a').css('transition','all 0s');
            // Cloned element should always have same left position and width as original element.
            orgElement = $('.original');
            coordsOrgElement = orgElement.offset();
            leftOrgElement = coordsOrgElement.left;
            widthOrgElement = orgElement.css('width');
            $('.cloned').css('left',leftOrgElement+'px').css('top',0).css('width',widthOrgElement).css('z-index','1000').show();
            $('.original').css('visibility','hidden');
        } else {
            // not scrolled past the menu; only show the original menu.
            $('.cloned').hide();
            $('.original').css('visibility','visible');

        }
    }
});

